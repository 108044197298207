import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  private _isVisible = false;

  @Input()
  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    if (this._isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  @Output()
  noClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SearchParams } from 'src/app/models/searchParams.model';
import { AuthService } from 'src/app/auth/auth.service';
import { OrderStatus } from '../order/order-status/order-status';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchModalComponent implements OnInit {
  @Output()
  isVisibleChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  filtersChange: EventEmitter<SearchParams> = new EventEmitter();

  private _isVisible = true;

  @Input()
  get isVisible(): boolean {
    return this._isVisible;
  }

  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    this.isVisibleChange.emit(isVisible);
  }

  /* private _date: {from?: Date, to?: Date} = {}; */

  company: string;
  buyer: string;
  supplier: string;
  orderNumber: string;
  selectedOrderStatus: OrderStatus;

  orderStatus = OrderStatus;

  constructor(
    public authService: AuthService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.isVisible = false;
    this.cd.markForCheck();
  }

  /* get dateFrom(): Date {
    return this._date.from;
  }

  set dateFrom(date: Date) {
    this._date.from = date;
  }

  get dateTo(): Date {
    return this._date.to;
  }

  set dateTo(date: Date) {
    this._date.to = date;
  } */

  submitFilters() {
    if (this.orderNumber) {
      this.filtersChange.emit({orderNumber: this.orderNumber});
    } else {
      this.filtersChange.emit({
        company: this.company,
        buyer: this.buyer,
        supplier: this.supplier,
        orderStatus: this.selectedOrderStatus
      });
    }
  }

  resetFilters() {
    /* this._date = {}; */
    this.orderNumber = null;
    this.company = null;
    this.buyer = null;
    this.supplier = null;
    this.selectedOrderStatus = null;
    this.filtersChange.emit();
  }

  get filtersDisabled(): boolean {
    return !!this.orderNumber;
  }
}

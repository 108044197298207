import { ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';

export class MyErrorHandler implements ErrorHandler {
    handleError(error) {
        if (!environment.production) {
            alert(JSON.stringify(error));
        }
    }
}

import { Injectable } from '@angular/core';
import { TextFilter, TextOperator, ListFilter, SingleArgumentFilter } from './psf-filters';

@Injectable({
  providedIn: 'root'
})
export class PsfService {
  private _filters = {
    order: {
      number: new TextFilter('number', TextOperator.CONTAINS, true),
      company: {
        contains: new TextFilter('company', TextOperator.CONTAINS, true)
      },
      buyerName: {
        contains: new TextFilter('buyerName', TextOperator.CONTAINS, true)
      },
      supplierName: {
        contains: new TextFilter('supplier', TextOperator.CONTAINS, true)
      },
      status: new ListFilter('outcome'),
      approvers: {
        involvedApprover: new SingleArgumentFilter('involvedApprover'),
        currentApprover: new TextFilter('currentPendingApprover', TextOperator.EQUALS)
      }
    }
  };

  constructor() {}

  get filters() {
    return this._filters;
  }
}

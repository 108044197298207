import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { flatMap, tap, map } from 'rxjs/operators';
import { User } from './user.model';
import { Role, RoleType } from './role.model';

declare var info: {
  environment: string,
  idm: string,
  realm: string
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _baseHref = '/internal/auth';
  private _authEndpoint = `${info.idm}/realms/${info.realm}/protocol/openid-connect/token`;
  loggedUserInfo: User = null;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  login(userCredentials: {username: string, password: string, rememberMe: boolean}): Observable<User> {
    let formData = new HttpParams()
      .set('username', userCredentials.username)
      .set('password', userCredentials.password)
      .set('grant_type', 'password')
      .set('client_id', 'eoda-pwa');
    if (userCredentials.rememberMe) {
      formData = formData.set('rememberMe', 'on');
    }
    return this.http.post(this._authEndpoint, formData).pipe(
      flatMap((res: {access_token: string, refresh_token: string}) => this.getUserInfo(res.access_token)),
      flatMap((res: User) => from(this.router.navigateByUrl('/dashboard')).pipe(map(a => res)))
    );
  }

  redirectToLoginPage() {
    this.router.navigateByUrl('/login');
  }

  logout() {
    this.loggedUserInfo = null;
    this.http.get(`${this._baseHref}/logout`).subscribe(
      () => this.redirectToLoginPage()
    );
  }

  getUserInfo(token?: string): Observable<User> {
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.get(`${this._baseHref}/login`, { headers }).pipe(
      map((res: any) => {
        return {
          assignedRoles: res.assignedRoles.map(
            role => ({
              approver: role.approver,
              companyCode: role.companyCode,
              companyDescription: role.companyDescription,
              notifications: role.notifications,
              signer: role.signer,
              type: this.mapRoleType(role.type),
              wholeVisibility: role.wholeVisibility
            } as Role)
          ),
          email: res.email,
          firstName: res.firstName,
          fiscalCode: res.fiscalCode,
          hsmSignature: res.hsmSignature,
          id: res.id,
          language: res.language,
          lastName: res.lastName,
          notificationDelayDays: res.notificationDelayDays
        };
      }),
      tap((user: User) => this.loggedUserInfo = user)
    );
  }

  private mapRoleType(type: string): RoleType {
    switch (type) {
      case 'R01': return RoleType.R01;
      case 'R02': return RoleType.R02;
      case 'R03': return RoleType.R03;
      case 'R04': return RoleType.R04;
      case 'R05': return RoleType.R05;
      default: return null;
    }
  }

  getRolesOfLoggedUser(): Array<Role> {
    if (!this.loggedUserInfo || ! this.loggedUserInfo.assignedRoles) {
      return [];
    }
    return this.loggedUserInfo.assignedRoles;
  }
}

<app-modal [isVisible]="isVisible">
    <div class="delete-confirm-container">
        <h3>{{ "DASHBOARD.ORDER.REJECT_CONFIRM.TITLE" | translate | uppercase }}</h3>
        <p class="msg">{{ "DASHBOARD.ORDER.REJECT_CONFIRM.MSG" | translate }}</p>
        <textarea name="note" id="note" class="note" required
            [attr.placeholder]="'DASHBOARD.ORDER.REJECT_CONFIRM.NOTE_PLACEHOLDER' | translate"
            cols="27" rows="3"
            (input)="onNotesChange($event)"></textarea>
        <button
            class="fedrigoni"
            (click)="confirmDelete()"
            [disabled]="noteIsBlank()"
            [ngClass]="{'disabled': noteIsBlank()}"
            >{{ "DASHBOARD.ORDER.REJECT_CONFIRM.CONFIRM" | translate | uppercase }}</button>
        <p class="close fedrigoni-link"
            (click)="closeModal()"
            >{{ "DASHBOARD.ORDER.CLOSE" | translate | uppercase }}</p>
    </div>
</app-modal>
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, flatMap, take } from 'rxjs/operators';
import { Order } from './order.model';
import { SearchParams } from 'src/app/models/searchParams.model';
import { OrderStatus } from './order-status/order-status';
import { Approver, ApproverAction } from './approver.model';
import { PageResponse } from 'src/app/page-response.model';
import { PsfService } from 'src/app/psf/psf.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private baseHref = '/internal/orders/';

  constructor(
    private http: HttpClient,
    private psfService: PsfService,
    private auth: AuthService
  ) { }

  getPage(
    pageIndex: number,
    pageSize: number,
    filters: SearchParams
  ): Observable<{ orders: Array<Order>, totOrders: number }> {
    return this.auth.getUserInfo().pipe(
      take(1),
      flatMap(
        userInfo => {
          let params = new HttpParams();
          params = params.set('page', pageIndex.toString());
          params = params.set('size', pageSize.toString());
          const psfFilters = {};
          if (filters) {
            if (filters.orderNumber) {
              const orderNumberFilter = this.psfService.filters.order.number.withValue(filters.orderNumber);
              psfFilters[orderNumberFilter.filterName] = orderNumberFilter.filterArgs;
            }
            if (filters.company) {
              const companyFilter = this.psfService.filters.order.company.contains.withValue(filters.company);
              psfFilters[companyFilter.filterName] = companyFilter.filterArgs;
            }
            if (filters.buyer) {
              const buyerFilter = this.psfService.filters.order.buyerName.contains.withValue(filters.buyer);
              psfFilters[buyerFilter.filterName] = buyerFilter.filterArgs;
            }
            if (filters.supplier) {
              const supplierFilter = this.psfService.filters.order.supplierName.contains.withValue(filters.supplier);
              psfFilters[supplierFilter.filterName] = supplierFilter.filterArgs;
            }
            if (filters.orderStatus) {
              const orderStatusFilter = this.psfService.filters.order.status.withValue([filters.orderStatus]);
              psfFilters[orderStatusFilter.filterName] = orderStatusFilter.filterArgs.map(
                status => status === 'PENDING' ? null : status
              );
            }
          } else {
            const orderStatusFilter = this.psfService.filters.order.status.withValue([OrderStatus.PENDING]);
            psfFilters[orderStatusFilter.filterName] = orderStatusFilter.filterArgs.map(
              status => status === 'PENDING' ? null : status
            );

            if (userInfo && !userInfo.assignedRoles.some(role => role.wholeVisibility)) {
              const involvedApproverFilter = this.psfService.filters.order.approvers.involvedApprover.withValue(userInfo.id);
              psfFilters[involvedApproverFilter.filterName] = involvedApproverFilter.filterArgs;
            }
          }
          if (Object.keys(psfFilters).length) {
            params = params.set('filters', JSON.stringify(psfFilters));
          }
          return this.http.get<PageResponse<any>>(this.baseHref + 'pwa', {params}).pipe(
            map(res => ({ orders: this.ordersMapper(res.data), totOrders: res.total })),
            catchError(err => {
              console.error(err);
              return of({ orders: [], totOrders: 0 });
            })
          );
        }
      )
    );
  }

  approveOrders(orderIdsToApprove: Array<number>): Observable<Array<{response: any, id: number, success: boolean}>> {
    const requests = orderIdsToApprove.map(id => this.http.post(this.baseHref + id + '/approve', null).pipe(
      map(res => ({response: res, id, success: true})),
      catchError(err => of({response: err, id, success: false}))
    ));
    return forkJoin(requests);
  }

  reviewOrder(id: number, note: string): Observable<any> {
    const body = { note };
    return this.http.post(this.baseHref + id + '/review', body);
  }

  rejectOrder(id: number, note: string): Observable<any> {
    const body = { note };
    return this.http.post(this.baseHref + id + '/reject', body);
  }

  getOrderPDFLink(orderId: number): string {
    return `${this.baseHref}${orderId}/pdf`;
  }

  private ordersMapper(orders: Array<any>): Array<Order> {
    return orders.map(order => this.orderMapper(order));
  }

  private orderMapper(data: any): Order {
    const order: Order = {
      id: data.id,
      number: data.number,
      type: data.type,
      typeDescription: data.typeDescription,
      date: new Date(data.date),
      acquisitionDate: new Date(data.arrived),
      lastUpdate: new Date(data.lastUpdate),
      supplier: data.supplierName,
      amount: {
        amount: data.amount.integralPart + (data.amount.fractionPart / Math.pow(10, data.amount.fractionDigits)),
        currency: data.amount.currency
      },
      paymentMethod: data.paymentConditions,
      stockStatus: data.stock === 'STOCK_UP',
      commissionCode: data.code,
      permissions: {
        canApprove: data.permissions.canApprove,
        canReview: data.permissions.canReview,
        canReject: data.permissions.canDelete
      },
      buyer: data.buyerName,
      isSupplierNotified: data.isSupplierNotified,
      orderStatus: this.mapOrderStatus(data.outcome),
      approvers: this.mapApprovers(data.approvers),
      company: data.company,
      status: data.status,
      outcomeNotes: data.outcomeNotes
    };
    return order;
  }

  private mapOrderStatus(status: string): OrderStatus {
    switch (status) {
      case 'APPROVED': return OrderStatus.APPROVED;
      case 'REJECTED': return OrderStatus.REJECTED;
      case 'CANCELED': return OrderStatus.CANCELED;
      case 'MANUAL': return OrderStatus.MANUAL;
      case 'ANOMALY': return OrderStatus.ANOMALY;
      default: return OrderStatus.PENDING;
    }
  }

  private mapApprovers(approvers: Array<any>): Array<Approver> {
    return approvers.map(
      approver => {
        return {
          step: approver.step,
          name: approver.name,
          id: approver.id,
          action: this.mapApproverAction(approver.action),
          notificationDelayDays: approver.notificationDelayDays
        };
      }
    ).sort(
      (a, b) => a.step - b.step
    );
  }

  private mapApproverAction(action: string): ApproverAction {
    switch (action) {
      case 'APPROVAL': return ApproverAction.APPROVAL;
      case 'REJECTION': return ApproverAction.REJECTION;
    }
  }
}

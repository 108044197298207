import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from './order-status';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  constructor(
    private i18n: TranslateService
  ) {}

  transform(value: OrderStatus): any {
    switch (value) {
      case OrderStatus.APPROVED:
        return this.i18n.get('DASHBOARD.ORDER.STATUS.APPROVED');
      case OrderStatus.REJECTED:
        return this.i18n.get('DASHBOARD.ORDER.STATUS.REJECTED');
      case OrderStatus.CANCELED:
        return this.i18n.get('DASHBOARD.ORDER.STATUS.CANCELED');
      case OrderStatus.MANUAL:
        return this.i18n.get('DASHBOARD.ORDER.STATUS.MANUAL');
      case OrderStatus.ANOMALY:
        return this.i18n.get('DASHBOARD.ORDER.STATUS.ANOMALY');
      case OrderStatus.PENDING:
        return this.i18n.get('DASHBOARD.ORDER.STATUS.PENDING');
    }
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PwaService } from './pwa.service';
import { OrderComponent } from './dashboard/order/order.component';
import { MyErrorHandler } from './errorHandler';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// i18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { I18nService } from './i18n.service';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

import { ModalComponent } from './modal/modal.component';
import { SearchModalComponent } from './dashboard/search-modal/search-modal.component';
import { ApprovedModalComponent } from './dashboard/order/approved-modal/approved-modal.component';
import { ReviewConfirmComponent } from './dashboard/order/review-confirm/review-confirm.component';
import { ReviewedModalComponent } from './dashboard/order/reviewed-modal/reviewed-modal.component';
import { DeleteConfirmComponent } from './dashboard/order/delete-confirm/delete-confirm.component';
import { DeletedModalComponent } from './dashboard/order/deleted-modal/deleted-modal.component';
import { UserInfoModalComponent } from './auth/user-info-modal/user-info-modal.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { OrderStatusIconComponent } from './dashboard/order/order-status/order-status-icon/order-status-icon.component';
import { EnumPipe } from './enum.pipe';
import { OrderStatusPipe } from './dashboard/order/order-status/order-status.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    OrderComponent,
    ModalComponent,
    SearchModalComponent,
    ApprovedModalComponent,
    ReviewConfirmComponent,
    ReviewedModalComponent,
    DeleteConfirmComponent,
    DeletedModalComponent,
    UserInfoModalComponent,
    OrderStatusIconComponent,
    EnumPipe,
    OrderStatusPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    BrowserAnimationsModule,
    ScrollingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: MyErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Making sure those services are instantiated
  constructor(
    private pwaService: PwaService,
    private i18nService: I18nService
  ) {}
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss']
})
export class UserInfoModalComponent implements OnInit {

  private _isVisible = true;

  @Output()
  isVisibleChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  get isVisible(): boolean {
    return this._isVisible;
  }

  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    this.isVisibleChange.emit(isVisible);
  }

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.isVisible = false;
  }

  logout() {
    this.isVisible = false;
    this.authService.logout();
  }

}

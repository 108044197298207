import { Component, OnInit, Input } from '@angular/core';
import { OrderStatus } from '../order-status';

@Component({
  selector: 'app-order-status-icon',
  templateUrl: './order-status-icon.component.html',
  styleUrls: ['./order-status-icon.component.scss']
})
export class OrderStatusIconComponent implements OnInit {

  @Input()
  orderStatus: OrderStatus;

  orderStautsEnum = OrderStatus;

  constructor() { }

  ngOnInit() {}

}

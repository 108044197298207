import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-deleted-modal',
  templateUrl: './deleted-modal.component.html',
  styleUrls: ['./deleted-modal.component.scss']
})
export class DeletedModalComponent implements OnInit {

  @Input()
  isVisible = false;

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.isVisible = false;
    this.closed.emit();
  }

}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  currentLang: 'en-US' | 'it-IT';

  constructor(
    translate: TranslateService
  ) {
    translate.addLangs(['en', 'it']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'en');
    switch (navigator.language.substr(0, 2)) {
      case 'en':
        this.currentLang = 'en-US';
        break;
      case 'it':
        this.currentLang = 'it-IT';
        break;
      default:
        this.currentLang = 'en-US';
        break;
    }
  }
}

<div class="order-container" [ngClass]="{'review': isInReview}">
    <header>
        <span class="company">{{ order.company | uppercase }}</span>
        <i class="fas fa-alarm-exclamation reminder" *ngIf="hasReminder"></i>
        <span class="number">Oda n. {{order.number}}</span>
        <app-order-status-icon [orderStatus]="order.orderStatus"
            class="statusIcon"></app-order-status-icon>
    </header>
    <p>
        <span class="label">{{ 'DASHBOARD.ORDER.AMOUNT' | translate }}</span>
        {{order.amount.amount | currency:order.amount.currency:'code':'1.2-2':i18n.currentLang}}</p>
    <p>
        <span class="label">{{ 'DASHBOARD.ORDER.SUPPLIER' | translate }}</span>
        {{order.supplier}}</p>
    <p>
        <span class="label">{{ 'DASHBOARD.ORDER.BUYER' | translate }}</span>
        {{order.buyer}}</p>
    <div
    class="extra-details"
    [ngClass]="{
        'expanded': detailsExpanded,
        'collapsed': !detailsExpanded
    }">
        <p>
            <span class="label">{{ 'DASHBOARD.ORDER.ACQUISITION_DATE' | translate }}</span>
            {{order.acquisitionDate | date:'mediumDate':undefined:i18n.currentLang}}</p>
        <p>
            <span class="label">{{ 'DASHBOARD.ORDER.REQUEST_DATE' | translate }}</span>
            {{order.date | date:'mediumDate':undefined:i18n.currentLang}}</p>
        <p *ngIf="order.commissionCode">
            <span class="label">{{ 'DASHBOARD.ORDER.STOCK_STATUS' | translate }}</span>
            <span [ngClass]="{
                'green': order.stockStatus,
                'red': !order.stockStatus
            }">
                {{order.commissionCode}}
            </span>
        </p>
        <p>
            <span class="label">{{ 'DASHBOARD.ORDER.PAYMENT_METHOD' | translate }}</span>
            {{order.paymentMethod}}</p>
      <p>
        <span class="label">{{ 'DASHBOARD.ORDER.TYPE' | translate }}</span>
        {{order.typeDescription}}</p>
      <p *ngIf="order.outcomeNotes">
        <span class="label">{{ 'DASHBOARD.ORDER.OUTCOME_NOTES' | translate }}</span>
        {{order.outcomeNotes}}</p>



        <!-- <p>
            <span class="label">{{ 'DASHBOARD.ORDER.TYPE' | translate }}</span>
            {{order.type}}</p>
        <p>
            <span class="label">{{ 'DASHBOARD.ORDER.COMMISSION_CODE' | translate }}</span>
            {{order.commissionCode}}</p> -->
      <div class="icons">
        <a class="pdf" [href]="pdfLink" target="_blank" *ngIf="order.orderStatus !== 'ANOMALY'">
            <i class="far fa-file-pdf"></i><span>PDF</span>
        </a>
        <i class="far fa-envelope mail" *ngIf="order.isSupplierNotified"></i>
      </div>
    </div>
    <div class="expand-arrow-container" (click)="toggleDetails()">
      <div class="approvers">
        <div class="approver" *ngFor="let approver of order.approvers; let last=last">
          <i class="far fa-check approved" *ngIf="approver.action === approvalAction.APPROVAL"></i>
          <i class="far fa-times rejected" *ngIf="approver.action === approvalAction.REJECTION"></i>
          <span>{{approver.name | uppercase}}</span>
          <span class="separator" *ngIf="!last">-</span>
        </div>
      </div>
        <i
            [class]="'fas ' + (detailsExpanded ? 'fa-angle-up' : 'fa-angle-down')"
            [ngClass]="{'hidden': hideExpandArrow}">
        </i>
    </div>
</div>

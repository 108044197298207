export interface Approver {
    step: number;
    name: string;
    id: string;
    action: ApproverAction;
    notificationDelayDays: number;
}

export enum ApproverAction {
    APPROVAL = 1, REJECTION
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from './order/order.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SearchParams } from '../models/searchParams.model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject } from 'rxjs';
import { Order } from './order/order.model';
import { throttleTime, mergeMap, scan, map, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { OrderStatus } from './order/order-status/order-status';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, {static: false})
  viewport: CdkVirtualScrollViewport;

  private selectedOrdersIds: Array<number> = [];

  expandedOrderNumber: number;

  isSearchModalVisible = false;
  isUserInfoModalVisible = false;
  isOrdersApprovedModalVisible = false;
  isReviewConfirmOpen = false;
  isReviewedModalOpen = false;
  isRejectionConfirmOpen = false;
  isRejectedModalOpen = false;

  private pageSize = 25;
  private firstPageIndex = 1;

  private pageIndex: BehaviorSubject<number>;
  orderList: BehaviorSubject<Array<Order>> = new BehaviorSubject([]);

  private filters: SearchParams;
  private ordersCount: {
    loaded: number,
    tot: number
  };

  constructor(
    public orderService: OrderService,
    private translate: TranslateService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.initOrderList();
  }

  get isProduction(): boolean {
    return environment.realm === 'fedrigoni';
  }

  private initOrderList() {
    this.selectedOrdersIds = [];
    this.expandedOrderNumber = null;
    this.pageIndex = new BehaviorSubject(this.firstPageIndex);
    this.ordersCount =  {
      loaded: 0,
      tot: undefined
    };
    this.pageIndex.pipe(
      throttleTime(500),
      mergeMap(i => {
        return this.orderService.getPage(i, this.pageSize, this.filters).pipe(
          tap(res => {
            this.ordersCount.tot = res.totOrders;
          }),
          map(res => res.orders)
        );
      }),
      scan((acc: Order[], batch: Order[]) => {
        const newOrders = batch.filter(order => acc.filter(o => o.id === order.id).length === 0);
        return [ ...acc, ...newOrders ];
      }, []),
    ).pipe(
      tap(orders => this.ordersCount.loaded = orders.length)
    ).subscribe(
      orders => this.orderList.next(orders),
      err => this.orderList.error(err),
      () => this.orderList.complete()
    );
    if (this.viewport) { this.viewport.scrollToIndex(0); }
  }

  refreshOrdersList() {
    this.initOrderList();
  }

  setFilters(filters: SearchParams) {
    this.filters = filters;
    this.isSearchModalVisible = false;
    this.initOrderList();
  }

  removeFilters() {
    this.filters = null;
    this.initOrderList();
  }

  onOrderIndexChange(newOrderIndex: number) {
    if (
      newOrderIndex <= (this.ordersCount.loaded - this.pageSize)
      || newOrderIndex > this.ordersCount.tot
    ) { return; }
    const nextPageIndex = Math.floor((newOrderIndex / this.pageSize) + this.firstPageIndex + 1);
    this.pageIndex.next(nextPageIndex);
  }

  toggleOrderSelection(orderId: number) {
    if (!this.isOrderSelectable(orderId)) { return; }
    if (this.selectedOrdersIds.includes(orderId)) {
      this.selectedOrdersIds = this.selectedOrdersIds.filter(id => id !== orderId);
    } else {
      this.selectedOrdersIds.push(orderId);
    }
  }

  isSelected(orderId: number): boolean {
    return this.selectedOrdersIds.includes(orderId);
  }

  toggleDetailsOfOrder(orderId: number) {
    if (this.expandedOrderNumber !== null && this.expandedOrderNumber !== orderId) {
      return;
    }
    if (this.expandedOrderNumber === orderId) {
      this.expandedOrderNumber = null;
    } else {
      this.expandedOrderNumber = orderId;
    }
  }

  get canApproveSelectedOrders(): boolean {
    if (this.selectedOrdersCount < 1) { return false; }
    return this.identifyNotApprovableOrdersIds().length === 0;
  }

  private identifyNotApprovableOrdersIds(): Array<number> {
    const notApprovableOrders: Array<number> = [];
    this.selectedOrdersIds.forEach(
      id => {
        const orderToBeApproved = this.getOrderById(id);
        if (!orderToBeApproved.permissions.canApprove) {
          notApprovableOrders.push(id);
        }
      }
    );
    return notApprovableOrders;
  }

  approveSelectedOrders() {
    if (this.selectedOrdersCount === 0) {
      alert(this.translate.instant('DASHBOARD.ORDER.APPROVE_MSGS.NOT_ENOUGH'));
      return;
    }
    const notApprovableOrders = this.identifyNotApprovableOrdersIds();
    if (notApprovableOrders.length > 0) {
      // TODO: format
      alert(this.translate.instant('DASHBOARD.ORDER.APPROVE_MSGS.NO_PERM', {
        orders: JSON.stringify(notApprovableOrders)
      }));
      return;
    }
    this.orderService.approveOrders(this.selectedOrdersIds).subscribe(
      res => {
        const failedIds = res.filter(r => !r.success).map(r => r.id);
        const failures = res.filter(r => !r.success).map(r => r.response);
        this.selectedOrdersIds = failedIds;
        if (failedIds.length === 0) {
          this.isOrdersApprovedModalVisible = true;
        } else if (failedIds.length === 1) {
          // TODO: format
          alert(this.translate.instant('DASHBOARD.ORDER.APPROVE_MSGS.SINGLE_ERROR', {
            order: failures[0]
          }));
        } else {
          // TODO: format
          alert(this.translate.instant('DASHBOARD.ORDER.APPROVE_MSGS.MULTIPLE_ERRORS', {
            orders: failedIds
          }));
        }
        this.initOrderList();
      },
      err => {
        alert(err.message);
      }
    );
  }

  get canReviewSelectedOrder(): boolean {
    if (this.selectedOrdersCount !== 1) { return false; }
    const orderToBeReviewed = this.getOrderById(this.selectedOrdersIds[0]);
    return orderToBeReviewed.permissions.canReview;
  }

  askReviewConfirm() {
    if (!this.checkOnlyOneOrderIsSelectedForReview()) {return; }
    const orderToReview = this.getOrderById(this.selectedOrdersIds[0]);
    if (orderToReview && orderToReview.permissions.canReview) {
      this.isReviewConfirmOpen = true;
    } else {
      alert(this.translate.instant('DASHBOARD.ORDER.REVIEW_MSGS.NO_PERM'));
    }
  }

  reviewSelectedOrder(event: any) {
    this.isReviewConfirmOpen = false;
    if (!this.checkOnlyOneOrderIsSelectedForReview()) { return; }
    this.orderService.reviewOrder(this.selectedOrdersIds[0], event.notes).subscribe(
      () => {
        this.isReviewedModalOpen = true;
        this.initOrderList();
      },
      (err: HttpErrorResponse) => {
        alert(err.message);
      }
    );
  }

  get selectedOrdersCount(): number {
    return this.selectedOrdersIds.length;
  }

  private checkOnlyOneOrderIsSelectedForReview(): boolean {
    if (!this.selectedOrdersCount) {
      alert(this.translate.instant('DASHBOARD.ORDER.REVIEW_MSGS.NOT_ENOUGH'));
      return false;
    }
    if (this.selectedOrdersCount > 1) {
      alert(this.translate.instant('DASHBOARD.ORDER.REVIEW_MSGS.MAX_ONE'));
      return false;
    }
    return true;
  }

  get canRejectSelectedOrder(): boolean {
    if (this.selectedOrdersCount !== 1) { return false; }
    const orderToBeReviewed = this.getOrderById(this.selectedOrdersIds[0]);
    return orderToBeReviewed.permissions.canReject;
  }

  askRejectionConfirm() {
    if (!this.checkOnlyOneOrderIsSelectedForRejection()) { return; }
    const orderToReject = this.getOrderById(this.selectedOrdersIds[0]);
    if (orderToReject && orderToReject.permissions.canReject) {
      this.isRejectionConfirmOpen = true;
    } else {
      alert(this.translate.instant('DASHBOARD.ORDER.REJECT_MSGS.NO_PERM'));
    }
  }

  rejectSelectedOrder(event: any) {
    this.isRejectionConfirmOpen = false;
    if (!this.checkOnlyOneOrderIsSelectedForRejection()) { return; }
    this.orderService.rejectOrder(this.selectedOrdersIds[0], event.notes).subscribe(
      () => {
        this.isRejectedModalOpen = true;
        this.initOrderList();
      },
      (err: HttpErrorResponse) => {
        alert(err.message);
      }
    );
  }

  private checkOnlyOneOrderIsSelectedForRejection(): boolean {
    if (!this.selectedOrdersCount) {
      alert(this.translate.instant('DASHBOARD.ORDER.REJECT_MSGS.NOT_ENOUGH'));
      return false;
    }
    if (this.selectedOrdersCount > 1) {
      alert(this.translate.instant('DASHBOARD.ORDER.REJECT_MSGS.MAX_ONE'));
      return false;
    }
    return true;
  }

  toggleSelectAll() {
    if (this.allSelected) {
      this.selectedOrdersIds = [];
    } else {
      this.orderList.value.forEach(
        order => {
          if (this.isOrderSelectable(order.id)) {
            this.selectedOrdersIds.push(order.id);
          }
        }
      );
    }
  }

  get allSelected(): boolean {
    if (this.selectedOrdersIds.length === 0) { return false; }
    for (const order of this.orderList.value) {
      if (this.isOrderSelectable(order.id) && !this.selectedOrdersIds.includes(order.id)) {
        return false;
      }
    }
    return true;
  }

  private getOrderById(orderId: number): Order {
    const matches = this.orderList.value.filter(order => order.id === orderId);
    if (matches.length > 1) {
      throw new Error('Multiple orders with same id');
    }
    return matches[0] || null;
  }

  isOrderSelectable(orderId: number): boolean {
    const order = this.getOrderById(orderId);
    if (order.orderStatus !== OrderStatus.PENDING) { return false; }
    return this.isCurrentApprover(order);
  }

  private isCurrentApprover(order: Order): boolean {
    if (!this.auth.loggedUserInfo) {
      return false;
    }
    const approvers = order.approvers.sort((a, b) => a.step - b.step);
    for (const approver of approvers) {
      if (!approver.action) {
        return approver.id === this.auth.loggedUserInfo.id;
      }
    }
    return false;
  }
}

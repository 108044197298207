import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Order } from './order.model';
import { I18nService } from 'src/app/i18n.service';
import { ApproverAction, Approver } from './approver.model';
import { OrderService } from './order.service';
import { AuthService } from 'src/app/auth/auth.service';
import { RoleType } from 'src/app/auth/role.model';
import * as moment from 'moment';
import { OrderStatus } from './order-status/order-status';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderComponent implements OnInit {

  approvalAction = ApproverAction;

  @Input()
  order: Order;

  @Input()
  hideExpandArrow = false;

  @Input()
  detailsExpanded = false;

  @Output()
  toggleDetails$: EventEmitter<void> = new EventEmitter();

  constructor(
    public i18n: I18nService,
    private orderService: OrderService,
    private auth: AuthService
  ) {}

  ngOnInit() { }

  toggleDetails() {
    if (this.hideExpandArrow) {
      return;
    }
    this.toggleDetails$.emit();
  }

  get pdfLink(): string {
    return this.orderService.getOrderPDFLink(this.order.id);
  }

  get hasReminder(): boolean {
    const currentApprover = this.getCurrentApprover();
    if (!currentApprover) { return false; }
    if (this.order.orderStatus !== OrderStatus.PENDING) {
      return false;
    }
    const isCurrentApprover = this.auth.loggedUserInfo.id === currentApprover.id;
    const canViewNotificationStatus = this.auth.loggedUserInfo.assignedRoles.some(
      role => role.companyCode === this.order.company
        && (role.type === RoleType.R04 || role.type === RoleType.R05)
    );
    return (
      (isCurrentApprover || canViewNotificationStatus)
        && currentApprover.notificationDelayDays !== 0
        && moment(this.order.lastUpdate).add(currentApprover.notificationDelayDays, 'days').isBefore(moment())
    );
  }

  private getCurrentApprover(): Approver {
    return this.order.approvers
      .filter(approver => !approver.action)
      .sort((a, b) => a.step < b.step ? -1 : a.step > b.step ? 1 : 0)[0];
  }

  get isInReview(): boolean {
    return this.order.status === 'REVIEW';
  }

}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(this.requestWithCredentials(req)).pipe(
        catchError((err: any, caught: Observable<HttpEvent<any>>) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.authService.redirectToLoginPage();
            return throwError('Must log in');
          }
          console.log('Unknown error', err);
          return throwError('Unknown error: ' + JSON.stringify(err));
        })
      );
    }

    requestWithCredentials(req: HttpRequest<any>): HttpRequest<any> {
      return req.clone({ withCredentials: true });
    }
}

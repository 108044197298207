import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  constructor(
    private swUpdate: SwUpdate,
    private translate: TranslateService
  ) {
    swUpdate.available.subscribe(
      () => {
        if (window.confirm(translate.instant('COMMON.PWA.UPDATE_AVAIBLE_PROMPT'))) {
          window.location.reload();
        }
      }
    );
  }
}

<app-modal [isVisible]="isVisible">
    <div class="modal-container">
        <i class="far fa-times" (click)="closeModal()"></i>
        <div class="form-container">
            <h3>{{ 'DASHBOARD.SEARCH.TITLE' | translate | uppercase }}</h3>
            <label for="number">{{ 'DASHBOARD.SEARCH.NUMBER' | translate }}</label>
            <input type="text" id="number" name="number"
                [ngModel]="orderNumber"
                (change)="orderNumber = $event.target.value">
            <div class="separator"></div>
            <label for="company" [ngClass]="{'disabled': filtersDisabled}"
                >{{ 'DASHBOARD.SEARCH.COMPANY' | translate }}</label>
            <select id="company" name="company"
                [(ngModel)]="company"
                [disabled]="filtersDisabled">
                <option [ngValue]="null">-</option>
                <option *ngFor="let role of authService.getRolesOfLoggedUser()" [value]="role.companyCode">{{role.companyDescription}}</option>
            </select>
            <!-- <label for="from">{{ 'DASHBOARD.SEARCH.FROM' | translate }}</label>
            <input type="date" id="from" name="from"
                [value]="dateFrom | date:'yyyy-MM-dd'"
                [disabled]="filtersDisabled"
                (change)="dateFrom = $event.target.valueAsDate">
            <label for="to">{{ 'DASHBOARD.SEARCH.TO' | translate }}</label>
            <input type="date" id="to" name="to"
                [value]="dateTo | date:'yyyy-MM-dd'"
                [disabled]="filtersDisabled"
                (change)="dateTo = $event.target.valueAsDate"> -->
            <!--<label for="buyer_code">{{ 'DASHBOARD.SEARCH.BUYER_CODE' | translate }}</label>
            <input type="text" id="buyer_code" name="buyer_code">
            <div class="separator"></div>-->
            <label for="buyer_description" [ngClass]="{'disabled': filtersDisabled}"
                >{{ 'DASHBOARD.SEARCH.BUYER_DESCRIPTION' | translate }}</label>
            <input type="text" id="buyer_description" name="buyer_description"
                [ngModel]="buyer"
                (change)="buyer = $event.target.value"
                [disabled]="filtersDisabled">
            <div class="separator"></div>
            <label for="supplier_description" [ngClass]="{'disabled': filtersDisabled}"
                >{{ 'DASHBOARD.SEARCH.SUPPLIER_DESCRIPTION' | translate }}</label>
            <input type="text" id="supplier_description" name="supplier_description"
                [ngModel]="supplier"
                (change)="supplier = $event.target.value"
                [disabled]="filtersDisabled">
            <div class="separator"></div>
            <label for="oda_status" [ngClass]="{'disabled': filtersDisabled}"
                >{{ 'DASHBOARD.SEARCH.ODA_STATUS' | translate }}</label>
            <select id="oda_status" name="oda_status"
                [(ngModel)]="selectedOrderStatus"
                [disabled]="filtersDisabled">
                <option [ngValue]="null">-</option>
                <option *ngFor="let status of orderStatus | enum" [value]="status">{{status | orderStatus | async}}</option>
            </select>
            <!-- <label for="stock_status">{{ 'DASHBOARD.SEARCH.STOCK_STATUS' | translate }}</label>
            <input type="text" id="stock_status" name="stock_status">
            <div class="separator"></div>
            <label for="sap_status">{{ 'DASHBOARD.SEARCH.SAP_STATUS' | translate }}</label>
            <select id="sap_status" name="sap_status"
                [disabled]="filtersDisabled"
                type="checkbox">
                <option [ngValue]="null">-</option>
                <option *ngFor="let status of sap_status" [value]="status">{{status}}</option>
            </select>
            <label for="approver">{{ 'DASHBOARD.SEARCH.APPROVER' | translate }}</label>
            <select id="approver" name="approver"
                [disabled]="filtersDisabled"
                type="checkbox">
                <option [ngValue]="null">-</option>
                <option *ngFor="let approver of approvers" [value]="approver">{{approver}}</option>
            </select> -->
            <div class="actions">
                <button class="fedrigoni" (click)="submitFilters()">{{ 'DASHBOARD.SEARCH.SEARCH' | translate | uppercase }}</button>
                <button class="fedrigoni" (click)="resetFilters()">{{ 'DASHBOARD.SEARCH.RESET' | translate | uppercase }}</button>
            </div>
        </div>
    </div>
</app-modal>
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

  showPassword = false;

  private _userCredentials = {
    username: '',
    password: '',
    rememberMe: false
  };

  passwordResetLink = `https://idm.moneytec.it/auth/realms/${environment.realm}/login-actions/reset-credentials?client_id=eoda-backoffice`;

  // TODO: place the real privacy policy link
  anchorTranslateArgs = {
    policyHref: '#'
  };

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  login() {
    this.authService.login(this._userCredentials).subscribe(
      res => {},
      err => {
        this.password = '';
        this.cd.markForCheck();
      }
    );
  }

  get username(): string {
    return this._userCredentials.username;
  }

  set username(username: string) {
    this._userCredentials.username = username;
  }

  get password(): string {
    return this._userCredentials.password;
  }

  set password(psw: string) {
    this._userCredentials.password = psw;
  }

  get saveCredentials(): boolean {
    return this._userCredentials.rememberMe;
  }

  set saveCredentials(rememberMe: boolean) {
    this._userCredentials.rememberMe = rememberMe;
  }

}

<div class="dashboard-container">
    <header [ngClass]="{'staging': !isProduction}">
        <h2>{{ 'DASHBOARD.TITLE' | translate | uppercase }}</h2>
        <i class="fas fa-sync-alt" (click)="refreshOrdersList()"></i>
        <i class="fas fa-search" (click)="isSearchModalVisible = true"></i>
        <i class="fas fa-bars" (click)="isUserInfoModalVisible = true"></i>
    </header>

    <div class="scroll-container">
        <cdk-virtual-scroll-viewport [itemSize]="167" class="orders-container"
            (scrolledIndexChange)="onOrderIndexChange($event)">
            <div class="order-container" *cdkVirtualFor="let order of orderList | async">
                <div class="checkbox" (click)="toggleOrderSelection(order.id)"
                    [ngClass]="{'selection_disabled': !isOrderSelectable(order.id)}">
                    <i class="fas fa-check" *ngIf="isSelected(order.id)"></i>
                </div>
                <app-order                
                    [order]="order"
                    [hideExpandArrow]="expandedOrderNumber !== null && expandedOrderNumber !== order.id"
                    [detailsExpanded]="order.id === expandedOrderNumber"
                    (toggleDetails$)="toggleDetailsOfOrder(order.id)">
                </app-order>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>

    <div class="actions">
        <div class="checkbox" (click)="toggleSelectAll()">
            <i class="fas fa-check" *ngIf="allSelected"></i>
        </div>
        <button
            type="button"
            class="approve fedrigoni"
            (click)="approveSelectedOrders()"
            [disabled]="!canApproveSelectedOrders"
            [ngClass]="{'disabled': !canApproveSelectedOrders}">
            {{ 'DASHBOARD.ORDER.APPROVE' | translate | uppercase }}</button>
        <button
            type="button"
            class="review fedrigoni"
            (click)="askReviewConfirm()"
            [disabled]="!canReviewSelectedOrder"
            [ngClass]="{'disabled': !canReviewSelectedOrder}">
            {{ 'DASHBOARD.ORDER.REVIEW' | translate | uppercase }}</button>
        <i class="fas fa-trash reject" (click)="askRejectionConfirm()"
            [ngClass]="{'disabled': !canRejectSelectedOrder}"></i>
    </div>

    <app-search-modal [(isVisible)]="isSearchModalVisible"
        (filtersChange)="setFilters($event)"></app-search-modal>
    <app-user-info-modal [(isVisible)]="isUserInfoModalVisible"></app-user-info-modal>
    <app-order-approved-modal
        [isVisible]="isOrdersApprovedModalVisible"></app-order-approved-modal>
    <app-order-review-confirm
        [(isVisible)]="isReviewConfirmOpen"
        (rewiewConfirmed)="reviewSelectedOrder($event)"></app-order-review-confirm>
    <app-order-reviewed-modal
        [isVisible]="isReviewedModalOpen"></app-order-reviewed-modal>
    <app-order-delete-confirm
        [(isVisible)]="isRejectionConfirmOpen"
        (deleteConfirmed)="rejectSelectedOrder($event)"></app-order-delete-confirm>
    <app-order-deleted-modal
        [isVisible]="isRejectedModalOpen"></app-order-deleted-modal>
</div>
import { PsfFitler } from './psf';

export class NumberFilter implements PsfFitler {
    constructor(
        private filterName: string,
        private operator: NumberOperator
    ) {}

    withValue(value: string) {
        return {
            filterName: this.filterName,
            filterArgs: [this.operator.toString(), value.toString()]
        };
    }
}

export enum NumberOperator {
    LT, LTE, EQ, GTE, GT
}

export class ListFilter implements PsfFitler {
    constructor(
        private filterName: string
    ) {}

    withValue(values: Array<string>) {
        return {
            filterName: this.filterName,
            filterArgs: values
        };
    }
}

export class TextFilter implements PsfFitler {
    constructor(
        private filterName: string,
        private operator: TextOperator,
        private ignoreCase = false
    ) {}

    withValue(value: string) {
        return {
            filterName: this.filterName,
            filterArgs: [
                this.operator.toString(),
                this.ignoreCase ? CaseSensitivity.IGNORE_CASE.toString() : CaseSensitivity.CASE_SENSITIVE.toString(),
                value
            ]
        };
    }
}

export enum TextOperator {
    CONTAINS = 'CONTAINS',
    STARTS_WITH = 'STARTS_WITH',
    ENDS_WITH = 'ENDS_WITH',
    EQUALS = 'EQUALS'
}

enum CaseSensitivity {
    CASE_SENSITIVE = 'CASE_SENSITIVE',
    IGNORE_CASE = 'IGNORE_CASE'
}

export class SingleArgumentFilter implements PsfFitler {
    constructor(
        private filterName: string
    ) {}
    withValue(value: string) {
        return {
            filterName: this.filterName,
            filterArgs: [value.toString()]
        };
    }
}

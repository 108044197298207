<div class="login-container">
    <img
        src="/assets/imgs/Fedrigoni_logo.png"
        alt="Fedrigoni logo"
        class="logo"/>

    <h2>{{ 'AUTH.LOGIN.TITLE' | translate | uppercase }}</h2>
    
    <div class="username">
        <input
            type="text"
            name="username"
            id="username-input"
            placeholder="username"
            [(ngModel)]="username">
    </div>

    <div class="password">
        <input
            [type]="showPassword ? 'text' : 'password'"
            name="password"
            id="password-input"
            placeholder="password"
            [(ngModel)]="password"
            (keyup.enter)="login()">
        <i [class]="'far ' + (showPassword ? 'fa-eye-slash' : 'fa-eye')"
            (click)="showPassword = !showPassword"></i>
    </div>

    <div class="submit-btn-container">
        <button
            class="login-btn"
            (click)="login()"
            >{{ 'AUTH.LOGIN.SUBMIT' | translate | uppercase }}
        </button>
    </div>

    <div class="separator"></div>

    <div class="save-credentials">
        <div class="checkbox" (click)="saveCredentials = !saveCredentials">
                <i class="fas fa-check" *ngIf="saveCredentials"></i>
        </div>
        <p>{{ 'AUTH.LOGIN.REMEMBER_ME' | translate | uppercase }}</p>
    </div>

    <a
        [href]="passwordResetLink"
        class="forgot-password"
        >{{ 'AUTH.LOGIN.FORGOT_PASSWORD' | translate | uppercase}}
    </a>

    <div class="separator"></div>

    <p class="privacy-info" [innerHTML]="'AUTH.LOGIN.PRIVACY' | translate:anchorTranslateArgs"></p>
</div>
<app-modal [isVisible]="isVisible" *ngIf="authService.loggedUserInfo">
    <div class="info-modal-container">
        <i class="far fa-times" (click)="closeModal()"></i>
        <div class="main-container">
            <h3>{{authService.loggedUserInfo.firstName}} {{authService.loggedUserInfo.lastName}}</h3>
            <p *ngIf="authService.loggedUserInfo.email">{{authService.loggedUserInfo.email}}</p>
            <!-- TODO: real privacy link -->
            <a href="www.google.com">privacy</a>
            <button class="fedrigoni" (click)="logout()"
                >{{ "AUTH.USER_INFO_MODAL.LOGOUT" | translate | uppercase }}</button>
        </div>
    </div>
</app-modal>
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-order-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent implements OnInit {

  private _isVisible = true;
  private _notes = '';

  @Output()
  isVisibleChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  get isVisible(): boolean {
    return this._isVisible;
  }

  set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    this.isVisibleChange.emit(isVisible);
  }

  @Output()
  deleteConfirmed: EventEmitter<{notes: string}> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.isVisible = false;
  }

  onNotesChange(event: Event) {
    this._notes = (event.target as HTMLTextAreaElement).value;
  }

  confirmDelete() {
    if (!this._notes || this._notes.trim().length === 0) {
      return;
    }
    this.deleteConfirmed.emit({
      notes: this._notes
    });
  }

  noteIsBlank() {
    return !this._notes || this._notes.trim().length === 0;
  }
}
